<template>
  <div class="card justify-content-center flex">
    <span @mouseover="onTextSpanMouseOver" @mouseleave="onTextSpanMouseLeave">{{
      displayName
    }}</span>
    <OverlayPanel
      ref="op"
      class="elevation-3 rounded-2xl"
      @mouseover="onOverlayPanelMouseOver"
      @mouseleave="hide"
    >
      <div>
        <p class="mb-3 text-lg font-semibold text-aubergine-900">
          {{ $t('parcel.bundleId') }}:
        </p>
        <div class="flex items-center">
          <p>
            {{
              bundleId && bundleId.length > 0
                ? bundleId
                : $t('parcel.noBundleId')
            }}
          </p>
          <MaterialIcon
            v-if="bundleId && bundleId.length > 0"
            v-tooltip.top="$t('general.copy')"
            icon="content_copy"
            class="ml-4"
            @click="copy(`${bundleId}`)"
          />
        </div>
        <p class="mb-3 text-lg font-semibold text-aubergine-900">
          {{ $t('parcel.medicineZINumber') }}:
        </p>
        <div class="flex items-center">
          <p>
            {{ originalArticleNumber ?? $t('parcel.noArticleNumber') }}
          </p>
          <MaterialIcon
            v-if="originalArticleNumber"
            v-tooltip.top="$t('general.copy')"
            icon="content_copy"
            class="ml-4"
            @click="copy(`${originalArticleNumber}`)"
          />
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import OverlayPanel from 'primevue/overlaypanel'
import { useClipboard, promiseTimeout } from '@vueuse/core'

const { copy } = useClipboard()
const op = ref()
const textSpanMouseOn = ref(false)
const overlayPanelMouseOn = ref(false)

function toggle(event: any) {
  op.value.show(event)
}

function hide() {
  op.value.hide()
}

function onTextSpanMouseOver(event: any) {
  textSpanMouseOn.value = true
  toggle(event)
}

async function onTextSpanMouseLeave() {
  textSpanMouseOn.value = false
  overlayPanelMouseOn.value = false
  await delayedHide()
}

function onOverlayPanelMouseOver() {
  overlayPanelMouseOn.value = true
}

async function delayedHide() {
  await promiseTimeout(300)
  if (!textSpanMouseOn.value && !overlayPanelMouseOn.value) {
    hide()
  }
}

defineProps<{
  displayName: string
  bundleId?: string
  originalArticleNumber?: string
}>()
</script>
