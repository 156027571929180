<template>
  <div>
    <div class="mt-4 grid grid-cols-[200px_1fr]">
      <h5 class="mb-4">{{ $t('printing.deliveryService') }}</h5>
      <h5>{{ $t('printing.carrier') }}</h5>
    </div>

    <div class="grid grid-cols-[200px_1fr] items-center space-y-4">
      <img
        src="/images/courier_logos/PostNL.png"
        class="mx-auto w-[50px]"
        alt="PostNl"
      />
      <PrintingDeliveryOption :options="optionsPostNL" />

      <img
        src="/images/courier_logos/Budbee.png"
        class="mx-auto w-[100px]"
        alt="Budbee"
      />
      <PrintingDeliveryOption :options="optionsBudbee" />

      <img
        src="/images/courier_logos/Trunkrs.png"
        class="mx-auto w-[100px]"
        alt="Trunkrs"
      />
      <PrintingDeliveryOption :options="optionsTrunkrs" />

      <img
        src="/images/courier_logos/AMP.png"
        class="mx-auto w-[100px]"
        alt="AMP groep"
      />
      <PrintingDeliveryOption :options="optionsAMP" />

      <img
        src="/images/courier_logos/Runner.jpg"
        class="mx-auto w-[100px]"
        alt="Runner"
      />
      <PrintingDeliveryOption :options="optionsSpoedkoerier" />

      <WpModal :open="labelChangeModalOpen" @close-modal="closeModal()">
        <h3 class="text-lg font-semibold text-aubergine-900">
          {{ t('printing.changeLabel') }}
        </h3>
        <span
          v-if="
            selectedParcel &&
            selectedParcel?.ShipmentMethod &&
            selectedShipmentMethod
          "
        >
          <p
            v-sane-html="
              t('printing.changeLabelFromTo', {
                fromLabel: getShipmentTypeLabel(selectedParcel?.ShipmentMethod),
                toLabel: getShipmentTypeLabel(selectedShipmentMethod)
              })
            "
            class="text-gray-900"
          />
          <p class="text-gray-900">
            {{
              t('printing.labelGetsCancelled', {
                label: getShipmentTypeLabel(selectedParcel?.ShipmentMethod)
              })
            }}
          </p>
        </span>
        <p class="mt-4 text-orange-400">
          {{ t('printing.sendcloudExtraFees') }}
        </p>
        <div class="mt-6 grid grid-cols-2 gap-4">
          <WpButton variant="dark-outline" @click="closeModal()">
            {{ $t('general.cancel') }}
          </WpButton>
          <WpButton variant="signal-blue" @click="handleSaveClicked()">
            {{ $t('general.save') }}
          </WpButton>
        </div>
      </WpModal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useShipment from '~/composables/useShipment'
import type { ChosenPickupPoint, ShipmentTypeEnum } from '~/models'

const {
  labelChangeModalOpen,
  selectedParcel,
  selectedShipmentMethod,
  userDetails,
  isActivePickupPoint
} = storeToRefs(usePrintingStore())

const { handleShipmentTypeClicked } = usePrinting()
const { getShipmentTypeLabel, isPickupPointActive } = useShipment()
const { toast } = useHelpers()
const { t } = useI18n()

const location = ref<ChosenPickupPoint>()
const optionsPostNL: ShipmentTypeEnum[] = [
  'postNLDefault',
  'postNLMailbox',
  'postNLHome',
  'postNLPickup',
  'postNLSignature'
]
const optionsTrunkrs: ShipmentTypeEnum[] = ['trunkrsDefault', 'trunkrsMailbox']
const optionsBudbee: ShipmentTypeEnum[] = ['budbeeBox']
const optionsAMP: ShipmentTypeEnum[] = ['ampCooled', 'ampCooledUncooled']
const optionsSpoedkoerier: ShipmentTypeEnum[] = [
  'spoedkoerierDefault',
  'spoedkoerierColleague'
]

onMounted(async () => {
  const deliveryPreference = userDetails.value?.DeliveryPreference
  if (deliveryPreference == 'pickupPoint') {
    location.value = userDetails.value?.PickupPoint

    if (location.value) {
      isActivePickupPoint.value = await isPickupPointActive(location.value.Id)
    }
  }
})

function closeModal() {
  labelChangeModalOpen.value = !labelChangeModalOpen
  selectedShipmentMethod.value = selectedParcel.value?.ShipmentMethod
}

function handleSaveClicked() {
  if (selectedShipmentMethod.value) {
    handleShipmentTypeClicked(selectedShipmentMethod.value as ShipmentTypeEnum)
  } else {
    toast.error('Er is iets mis gegaan bij het aanpassen van het label')
  }

  labelChangeModalOpen.value = !labelChangeModalOpen
}
</script>
