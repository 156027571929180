import { configure, defineRule, Form, Field, ErrorMessage } from 'vee-validate'
import { min, min_value } from '@vee-validate/rules'

configure({
  validateOnInput: true
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('VForm', Form)
  nuxtApp.vueApp.component('VField', Field)
  nuxtApp.vueApp.component('VErrorMessage', ErrorMessage)

  const { t } = nuxtApp.$i18n
  const { apiGet } = useApi()

  const apiGetDebounced = useDebounceFn<(value: string) => Promise<WciaInfo>>(
    async (value: string) => {
      return await apiGet<WciaInfo>('standards/wcia', {
        query: { code: value }
      })
    },
    250
  )

  const { wciaDescription } = storeToRefs(useMedicationStore())

  defineRule('required', (value: string | any[] | number) => {
    if (!value) {
      return t('form.required')
    }
    return true
  })
  defineRule('email', (value: string) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    // Check if email
    if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
      return t('form.invalidEmail')
    }
    return true
  })
  defineRule('min', min)
  defineRule('min_value', min_value)
  defineRule('requiredDateRange', (value: Date[]) => {
    if (!value || value.length !== 2 || !value[0] || !value[1]) {
      return t('form.required')
    }
    return true
  })

  defineRule('wciaCode', async (value: string) => {
    try {
      const result = await apiGetDebounced(value)
      wciaDescription.value = result.readable
    } catch (e) {
      return t('form.invalidWciaCode')
    }

    return true
  }),
    defineRule('optionSelected', (value: any) => {
      if (typeof value !== 'object') {
        return t('form.optionNotSelected')
      }

      return true
    })
})

interface WciaInfo {
  code: string
  readable: string
}
