import type { ShipmentType } from '~/models'

export default function useShipment() {
  const { t } = useI18n()
  const { apiGet } = useApi()

  const ShipmentTypes: ShipmentType = {
    spoedkoerierDefault: {
      id: 'Spoedkoerier',
      label: t('shipmentMethod.spoedkoerierDefault'),
      description: t('shipmentMethod.spoedkoerierDefaultDescription')
    },
    spoedkoerierColleague: {
      id: 'Collega',
      label: t('shipmentMethod.spoedkoerierColleague'),
      description: t('shipmentMethod.spoedkoerierColleagueDescription')
    },
    postNLDefault: {
      id: '1',
      label: t('shipmentMethod.postNLDefault'),
      description: t('shipmentMethod.postNLDefaultDescription')
    },
    postNLMailbox: {
      id: '39',
      label: t('shipmentMethod.postNLMailbox'),
      description: t('shipmentMethod.postNLMailboxDescription')
    },
    postNLHome: {
      id: '6',
      label: t('shipmentMethod.postNLHome'),
      description: t('shipmentMethod.postNLHomeDescription')
    },
    postNLPickup: {
      id: '7',
      label: t('shipmentMethod.postNLPickup'),
      description: t('shipmentMethod.postNLPickupDescription')
    },
    postNLSignature: {
      id: '5',
      label: t('shipmentMethod.postNLSignature'),
      description: t('shipmentMethod.postNLSignatureDescription')
    },
    trunkrsDefault: {
      id: '2801',
      label: t('shipmentMethod.trunkrsDefault'),
      description: t('shipmentMethod.trunkrsDefaultDescription')
    },
    trunkrsMailbox: {
      id: '2801 - Brievenbus',
      label: t('shipmentMethod.trunkrsMailbox'),
      description: t('shipmentMethod.trunkrsMailboxDescription')
    },
    budbeeBox: {
      id: '3814',
      label: t('shipmentMethod.budbeeBox'),
      description: t('shipmentMethod.budbeeBoxDescription')
    },
    ampCooled: {
      id: 'Cooled',
      label: t('shipmentMethod.ampCooled'),
      description: t('shipmentMethod.ampCooledDescription')
    },
    ampCooledUncooled: {
      id: 'CooledUncooled',
      label: t('shipmentMethod.ampCooledUncooled'),
      description: t('shipmentMethod.ampCooledUncooledDescription')
    }
  }

  function getShipmentTypeLabel(shipmentType: string | undefined) {
    if (shipmentType && ShipmentTypes[shipmentType]) {
      return ShipmentTypes[shipmentType].label
    }

    if (shipmentType) return shipmentType

    return ''
  }

  async function getPickupPointData(pickupPointId: string) {
    try {
      const locationData: any = await apiGet(
        'shipping/{provider}/pickuppoint-active/{shipmentId}',
        {},
        { provider: 'sendcloud', shipmentId: pickupPointId }
      )

      return locationData.Data
    } catch (error) {
      console.log('Error checking location: ', error)
      return error
    }
  }

  async function isPickupPointActive(pickupPointId: string): Promise<boolean> {
    return await getPickupPointData(pickupPointId)
  }

  return {
    getShipmentTypeLabel,
    isPickupPointActive,
    ShipmentTypes
  }
}
