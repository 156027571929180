<template>
  <div class="relative inline">
    <label
      v-if="label"
      :for="name"
      class="font-baloo2 text-xl font-semibold"
      :class="labelClass"
    >
      {{ labelName }}
    </label>
    <VField
      v-slot="{ field, meta }"
      :name="name"
      :rules="rules"
      :type="type"
      :value="modelValue"
      @input="checkInput"
    >
      <slot name="prepend" />
      <input
        v-bind="field"
        :id="name"
        :value="modelValue"
        :min="min"
        class="block w-full rounded-md border-gray-300 focus:border-aubergine-500 focus:outline-none focus:ring-aubergine-500 disabled:cursor-not-allowed disabled:bg-gray-100"
        :class="[
          inputClass,
          {
            'border-green-300 focus:border-green-500 focus:ring-green-500':
              meta.valid && meta.touched && rules,
            'border-red-300 focus:border-red-500 focus:ring-red-500':
              !meta.valid && meta.touched && rules
          }
        ]"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        @keypress="onlyNumber"
      />

      <VErrorMessage
        v-if="showErrorMessage && meta.touched"
        :name="name"
        as="p"
        class="text-sm italic text-red-500"
      />
    </VField>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    default: null,
    required: true
  },
  label: {
    type: String,
    required: false
  },
  labelClass: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  min: {
    type: Number,
    default: 1
  },
  name: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  rules: {
    type: String,
    default: ''
  },
  onlyNumbers: {
    type: Boolean,
    default: false
  },
  showErrorMessage: {
    type: Boolean,
    default: true
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  inputId: {
    type: Object,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'enter'])

const labelName = computed(() => {
  return props.rules == 'required' ? `${props.label} *` : props.label
})

function onlyNumber($event) {
  if (props.onlyNumbers) {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which
    if (keyCode < 48 || keyCode > 57) {
      $event.preventDefault()
    }
  }
  if ($event.keyCode === 13) emit('enter')
}

function checkInput(event) {
  emit('update:modelValue', event.target.value)
}
</script>
